import { WVnc } from "../../components/Vnc.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../api/resourceTypeException.ts";
import { assignedVmsQueryAtom } from "./joinAssignedQuery.ts";

export function Vnc({ vmId, location }: { vmId: string; location: string }) {
    const vms = useSuspenseQueryAtom(assignedVmsQueryAtom(location));

    const vm = getResourceById(vms, vmId, "virtual_machine");

    return <WVnc vm={vm} location={location} />;
}

export default Vnc;
