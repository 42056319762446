import C from "../modules/main/TopNav.module.css";

import { Link } from "@tanstack/react-router";
import type { AdminVirtualMachine } from "@warrenio/api-spec/spec.oats.gen";
import { useStore } from "jotai/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { VncScreen, type VncScreenHandle } from "react-vnc";
import invariant from "tiny-invariant";
import { currentApiParamsAtom } from "../modules/api/apiClient.store.ts";
import { type ApiParameters, getWebsocketUrl } from "../modules/api/createApiClient.ts";
import type { VirtualMachineWithAssigned } from "../modules/compute/joinAssignedQuery.ts";
import { Toolbar } from "./Toolbar.tsx";
import { ToolbarText } from "./ToolbarText.tsx";
import { WToolButton } from "./button/WToolButton.tsx";

interface VncWsUrl extends ApiParameters {
    uuid: string;
    location: string;
    isAdmin: boolean;
}

function getVncWsUrl({ uuid, location, isAdmin, apikey, apiPrefix }: VncWsUrl) {
    invariant(apikey, "API key must be set");
    const search = new URLSearchParams({
        apikey,
        token: `${apikey}:${uuid}${isAdmin ? ":admin" : ""}`,
    });
    return `${getWebsocketUrl(apiPrefix)}/${location}/vm-console?${search}`;
}

function getVncPassword(uuid: string) {
    return uuid.split("").reverse().join("");
}

/**
 * Documentation:
 * - https://github.com/roerohan/react-vnc
 *      - Source code: https://github.com/roerohan/react-vnc/blob/main/src/lib/VncScreen.tsx
 * - https://github.com/novnc/noVNC/blob/master/docs/API.md
 */
export function WVnc({
    vm,
    location,
    isAdmin = false,
}: {
    vm: VirtualMachineWithAssigned | AdminVirtualMachine;
    location: string;
    isAdmin?: boolean;
}) {
    const displayRef = useRef<VncScreenHandle>(null);
    const store = useStore();
    const apiParams = useMemo(() => store.get(currentApiParamsAtom), [store]);
    const [isConnected, setIsConnected] = useState(false);
    const [isConnecting, setIsConnecting] = useState(true);

    const { uuid, name } = vm;

    useEffect(() => {
        document.title = `${name} - Terminal`;
    }, [name]);

    // TODO: Resize remote screen to fit

    return (
        <div className="flex flex-col h-full flex-1">
            <div>
                <div className="flex justify-between">
                    <div className="flex-1">
                        <Toolbar>
                            <ToolbarText icon="jp-monitor-icon size-0.875rem color-primary">{`${name} - Terminal`}</ToolbarText>

                            <WToolButton
                                icon="jp-media-loop-icon"
                                label="Send Ctrl+Alt+Del"
                                action={() => {
                                    displayRef.current?.sendCtrlAltDel();

                                    // Move focus back from button to screen
                                    displayRef.current?.focus();
                                }}
                            />
                            <WToolButton
                                icon="jp-icon-refresh"
                                label="(Re)Connect"
                                action={() => {
                                    setIsConnecting(true);
                                    displayRef.current?.connect();

                                    displayRef.current?.focus();
                                }}
                                isDisabled={isConnected}
                            />
                            {import.meta.env.DEV && (
                                // Allow disconnecting in dev to test loading state

                                <WToolButton
                                    icon="i-lucide:bug"
                                    label="Disconnect"
                                    action={() => {
                                        setIsConnecting(false);
                                        displayRef.current?.disconnect();

                                        displayRef.current?.focus();
                                    }}
                                    isDisabled={!isConnected}
                                />
                            )}

                            {/* <Spacer /> */}
                        </Toolbar>
                    </div>

                    <div className="p-2">
                        <ToolbarText>
                            <Link to="/" aria-label="Home" className={`bg-icon ${C.logo}`} />
                        </ToolbarText>
                    </div>
                </div>
            </div>

            <div
                // Extra wrapper component so we can position the loading indicator on top
                className="flex-grow flex flex-col relative"
            >
                <VncScreen
                    ref={displayRef}
                    // Style
                    // NB: This applies just to the wrapper <div>, not the screen canvas...
                    className="flex-grow bg-#000"
                    // NB: The loadingUI component is displayed *outside* the VncScreen (ie. as a sibling element)
                    loadingUI={<LoadingIndicator isConnecting={isConnecting} />}
                    url={getVncWsUrl({ uuid, location, isAdmin, ...apiParams })}
                    // Options
                    autoConnect
                    scaleViewport
                    // Events
                    onConnect={(rfb) => {
                        console.debug("onConnect", rfb);
                        displayRef.current!.focus();
                        setIsConnected(true);
                    }}
                    onDisconnect={(rfb) => {
                        console.debug("onDisconnect", rfb);
                        setIsConnected(false);
                    }}
                    onSecurityFailure={(rfb) => {
                        console.debug("onSecurityFailure", rfb);
                        // TODO: Display errors
                    }}
                    onCredentialsRequired={(rfb) => {
                        console.debug("onCredentialsRequired", rfb);
                        rfb!.sendCredentials({ password: getVncPassword(uuid) });
                    }}
                />
            </div>
        </div>
    );
}

function LoadingIndicator({ isConnecting }: { isConnecting: boolean }) {
    return (
        <div className="bg-grey-2 text-muted p-2 rounded m-2 absolute left-0 top-0">
            {isConnecting ? "Connecting..." : "Disconnected"}
        </div>
    );
}
